<template>
  <div
    v-if="!layoutMobile"
    :class="{
      'user-item relative p-[20px] rounded-[12px] min-w-[230px]': true,
      '!bg-[#EAECF0] border-[1px] border-[#EAECF0] border-solid':
        getStatusUser(props.user) === 'disable' ||
        getStatusUser(props.user) === 'pending' ||
        getStatusUser(props.user) === 'created' ||
        getStatusUser(props.user) === 'deleted' ||
        getStatusUser(props.user) === 'expired'
    }"
    v-on:click="handleNaviDetailsUser"
  >
    <span
      v-if="user.status"
      :class="{
        'absolute top-[10px] right-[10px] flex justify-center items-center w-[56px] h-[24px] rounded-[8px] border-[1px] border-solid text-[12px] noto500 leading-[22px]': true,
        'border-[#F38744] text-[#F38744] bg-white':
          getStatusUser(props.user) === 'active',
        'border-[#667085] text-[#667085] bg-white':
          getStatusUser(props.user) === 'pending',
        'border-[#D0D5DD] text-white bg-[#D0D5DD]':
          getStatusUser(props.user) === 'disable',
        hidden: getStatusUser(props.user) === 'created',
        'border-[#98A2B3] bg-white text-[#98A2B3]':
          getStatusUser(props.user) === 'deleted' ||
          getStatusUser(props.user) === 'expired'
      }"
    >
      {{
        getStatusUser(props.user) === 'active' ||
        getStatusUser(props.user) === 'expired'
          ? $t('common.under_contract')
          : getStatusUser(props.user) === 'pending'
          ? $t('common.unauthorized')
          : getStatusUser(props.user) === 'created'
          ? ''
          : $t('common.in_active')
      }}
    </span>
    <span
      v-if="user.status && getStatusUser(props.user) === 'expired'"
      :class="{
        'absolute top-[10px] left-[10px] flex justify-center items-center w-[56px] h-[24px] rounded-[8px] border-[1px] border-solid text-[12px] noto500 leading-[22px] border-[#98A2B3] bg-white text-[#98A2B3]': true
      }"
    >
      {{ $t('common.expired') }}
    </span>

    <div class="img w-[100px] h-[100px] relative">
      <img
        :class="{ 'w-[100px] h-[100px] rounded-full object-cover': true }"
        :src="getAvatarUserByName(props?.user?.avatar)"
        alt=""
        @load="handleImageLoad"
        @error="handleImageError"
        style="
          box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
            rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        "
      />
      <a-spin
        :indicator="indicatorLoadingAvatar"
        v-if="loading"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      />
    </div>
    <div
      class="name--account-name flex justify-center items-center flex-col pt-[12px] w-full"
      v-on:click="handleClickSeeDetails"
    >
      <h3
        :class="{
          'name w-full text-center text-[16px] noto600 pb-[4px]': true
        }"
      >
        {{ props?.user?.nickName }}
      </h3>
      <span :class="{ 'account-name w-full text-center noto400': true }">{{
        props?.user?.userName
      }}</span>
    </div>
  </div>
  <div
    v-else
    :class="{
      'user-item flex-col relative px-[20px] py-[28px] rounded-[12px] min-w-[230px]': true,
      '!bg-[#EAECF0] border-[1px] border-[#EAECF0] border-solid':
        getStatusUser(props.user) === 'disable' ||
        getStatusUser(props.user) === 'pending' ||
        getStatusUser(props.user) === 'created' ||
        getStatusUser(props.user) === 'deleted' ||
        getStatusUser(props.user) === 'expired'
    }"
    v-on:click="handleNaviDetailsUser"
  >
    <span
      v-if="user.status"
      :class="{
        'absolute top-[0px] left-[50%] translate-x-[-50%] translate-y-[-50%] flex justify-center items-center w-[56px] h-[24px] rounded-[8px] border-[1px] border-solid text-[12px] noto500 leading-[22px] ': true,
        'border-[#F38744] text-[#F38744] bg-white':
          getStatusUser(props.user) === 'active',
        'border-[#667085] text-[#667085] bg-white':
          getStatusUser(props.user) === 'pending',
        'border-[#D0D5DD] text-white bg-[#D0D5DD]':
          getStatusUser(props.user) === 'disable',
        hidden: getStatusUser(props.user) === 'created',
        'border-[#98A2B3] bg-white text-[#98A2B3]':
          getStatusUser(props.user) === 'deleted' ||
          getStatusUser(props.user) === 'expired'
      }"
    >
      {{
        getStatusUser(props.user) === 'active' ||
        getStatusUser(props.user) === 'expired'
          ? $t('common.under_contract')
          : getStatusUser(props.user) === 'pending'
          ? $t('common.unauthorized')
          : getStatusUser(props.user) === 'created'
          ? ''
          : $t('common.in_active')
      }}
    </span>
    <span
      v-if="user.status && getStatusUser(props.user) === 'expired'"
      :class="{
        'absolute top-[0px] left-[0%] translate-y-[-50%] flex justify-center items-center w-[56px] h-[24px] rounded-[8px] border-[1px] border-solid text-[12px] noto500 leading-[22px] border-[#98A2B3] bg-white text-[#98A2B3]': true
      }"
    >
      {{ $t('common.expired') }}
    </span>
    <div class="img w-[80px] h-[80px] relative">
      <img
        :class="{ 'w-[80px] h-[80px] rounded-full object-cover': true }"
        :src="getAvatarUserByName(props?.user?.avatar)"
        alt=""
        @load="handleImageLoad"
        @error="handleImageError"
        style="
          box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
            rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        "
      />
      <a-spin
        :indicator="indicatorLoadingAvatar"
        v-if="loading"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      />
    </div>
    <div
      class="name--account-name flex justify-center items-center flex-col pt-[12px] w-full"
      v-on:click="handleClickSeeDetails"
    >
      <h3
        :class="{
          'name w-full text-center text-[16px] noto600 pb-[4px]': true
        }"
      >
        {{ props?.user?.nickName }}
      </h3>
      <span :class="{ 'account-name w-full text-center noto400': true }">{{
        props?.user?.userName
      }}</span>
    </div>
  </div>
</template>
<script setup>
import { LoadingOutlined } from '@ant-design/icons-vue';
import { computed, h, ref } from 'vue';
import { useRouter } from 'vue-router';
import {
  getAvatarUserByName,
  getStatusUser,
  handleImageError
} from '../../../../helpers';
import { useStore } from 'vuex';
const router = useRouter();
const store = useStore();
const layoutMobile = computed(() => store.state.layoutMobile);
const props = defineProps({
  user: {
    type: Object,
    required: true
  }
});
const loading = ref(true);
const indicatorLoadingAvatar = h(LoadingOutlined, {
  style: {
    fontSize: '25px',
    color: 'rgba(239, 104, 32, 1)'
  },
  spin: true
});
const getOptionNavigation = () => {
  const pathCurrent = router.currentRoute.value.path;
  const pathKey = pathCurrent.includes('/total-users')
    ? 'total-users'
    : pathCurrent.includes('/users-by-store')
    ? 'users-by-store'
    : pathCurrent.includes('/account-permission')
    ? 'account-permission'
    : 'default';
  switch (pathKey) {
    case 'total-users':
      return 1;
    case 'users-by-store':
      return 2;
    case 'account-permission':
      return 3;
    default:
      return 1;
  }
};
const handleNaviDetailsUser = () => {
  const pathCurrent = router.currentRoute.value.path;
  const shop = router.currentRoute.value.query.shop;
  const page = router.currentRoute.value.query.page ?? 1;
  // const path = `${pathCurrent}/user-details-${getStatusUser(props.user)}`;
  const path = `${pathCurrent}`;
  const query = { option: getOptionNavigation(), id: props?.user?._id };
  router.push({
    path,
    query: {
      shop,
      page,
      shop_tab: 2,
      user_tab: getStatusUser(props.user),
      ...query
    }
  });
};
const handleImageLoad = () => {
  loading.value = false;
};
</script>
<style scoped lang="scss">
$four_item_on_rows: 4;
$three_item_on_rows: 3;
$two_item_on_rows: 2;
$gap: 24px;
.user-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 202px;
  width: calc(
    (100% / #{$four_item_on_rows}) -
      (#{$gap} * (#{$four_item_on_rows} - 1) / #{$four_item_on_rows})
  );
  @media screen and (max-width: 1400px) {
    width: calc(
      (100% / $three_item_on_rows) -
        (#{$gap} * ($three_item_on_rows - 1) / $three_item_on_rows)
    );
  }
  @media screen and (max-width: 1000px) {
    width: calc(
      (100% / $two_item_on_rows) -
        (#{$gap} * ($two_item_on_rows - 1) / $two_item_on_rows)
    );
  }
  border: 1px solid var(--border-color);
  cursor: pointer;
  background-color: var(--brg-light-color);
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
  &:active {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
  }
  .name,
  .account-name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    max-width: 180px;
  }
}
@media screen and (max-width: 700px) {
  .user-item {
    min-width: 150px !important;
  }
}
</style>
