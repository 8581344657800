<template>
  <!-- PC ONLY -->
  <div v-if="!layoutMobile" class="store-info-container pb-4 px-[24px] py-[21px] bg-[#f9fafb]">
    <span class="text-header-bold text-[24px] noto700">
      <!-- {{ $t('common.stores_creation') }} -->
      {{ userTab ? $t('common.personal_info') : nameByIndex }}
    </span>
    <div class="store-breadcrumb pt-[14px] pb-[24px]">
      <Breadcrumd :routes="routes" />
    </div>
    <div class="flex justify-center flex-col items-center gap-[24px] h-fit transition-all">
      <div :class="{
        'navigation-tab flex w-full items-center self-start border-b border-[#D0D5DD]': true
      }">
        <div v-for="(item, index) in navigateTab" :key="index">
          <div :class="{
            'px-5 pb-4 cursor-pointer noto400 ': true,
            'border-b-2 text-[#F38744] border-[#F38744] !font-bold':
              currentTab == index + 1
          }" @click="handleNavigateTabClick(index + 1)">
            {{ item.name }}
          </div>
        </div>
      </div>

      <div v-if="currentTab == 1" class="w-full flex gap-6">
        <div class="create-store-form bg-white p-[24px] w-[70%] m-auto rounded-lg border border-gray-border">
          <div class="form-header flex justify-between items-center">
            <div class="form-header-left text-gray600 noto700 text-[20px] uppercase">
              {{ shopInfo.name }}
            </div>
            <div class="form-header-right flex gap-2">
              <button :disabled="loadingSendEmail" v-if="shopInfo?.adminShop?.firstLogin === 1" type="button" :class="{
                'w-[144px] h-[42px] flex justify-center items-center notosans500 text-[14px] leading-[18px] text-white bg-[#12B76A] rounded-[8px] border-solid border-[1px] border-[#12B76A]': true,
                'bg-[#3b976c]': loadingSendEmail
              }" @click="handleResendEmail">
                {{ $t('common.resend_confirmation_email') }}
              </button>
              <button type="button"
                class="h-[42px] flex justify-center items-center text-delete-text border border-delete-text px-2 py-1 md:py-3 md:px-4 rounded-lg whitespace-nowrap"
                @click="handleDeletePermanentlyStoreAccount">
                {{ $t('common.store_deletion') }}
              </button>
              <button type="button"
                class="h-[42px] flex justify-center items-center bg-orage-text text-white px-2 py-1 md:py-[13px] md:px-4 rounded-lg whitespace-nowrap"
                @click="handleDisableStoreAccount">
                {{ $t('common.store_hidden') }}
              </button>
            </div>
          </div>

          <a-form :model="shopInfo" @finish="onSaveUpdate" autocomplete="off">
            <div class="input-row">
              <div class="input-row-left noto500 min-w-[200px]">
                {{ $t('common.store_logo_new') }}
              </div>
              <div class="input-row-right">
                <a-form-item name="">
                  <div class="logo-section flex items-center xs:flex-col">
                    <div class="avatar-container xs:mb-[10px]">
                      <div class="store-logo relative">
                        <img v-on:click="handleUploadAvatar" id="avatarShop"
                          class="w-[80px] h-[80px] object-cover cursor-pointer rounded-full" :src="shopAvatarRender"
                          alt="" @error="(e) => handleImageError(e, 'shop')"
                          @load="(e) => handleAvatarLoadingFinish(e)" />
                        <button type="button" :disabled="loadingAvatarShop" v-on:click="handleUploadAvatar" :class="{
                          'flex justify-center items-center absolute bottom-0 right-0 w-[32px] h-[32px] rounded-full bg-[#F38744]': true,
                          '!bg-white': loadingAvatarShop
                        }">
                          <a-spin v-if="loadingAvatarShop" class="flex justify-center items-center w-[20px] h-[20px]" />
                          <span v-else class="icon-camera" v-html="IconCamera"></span>
                        </button>
                      </div>
                      <input type="file" id="shop-avatar" accept="image/*" @change="handleEventChangeFileAvatar" />
                    </div>
                    <button type="button" :disabled="loadingAvatarShop"
                      class="delete-btn flex items-center gap-[6px] px-4 py-2 border-[1px] border-delete-text rounded-lg ml-4 xs:ml-0 cursor-pointer"
                      v-if="shopAvatarRender !== null" @click="handleDeleteShopAvatar">
                      <div v-html="TrashFilledIcon"></div>
                      <div class="text-delete-text whitespace-nowrap">
                        {{ $t('common.delete_image') }}
                      </div>
                    </button>
                  </div>
                </a-form-item>
              </div>
            </div>
            <div class="input-row">
              <div class="input-row-left noto500 min-w-[200px]">
                {{ $t('common.store_name') }}
              </div>
              <div class="input-row-right">
                <a-form-item name="name">
                  <a-input v-model:value="shopInfo.name" />
                </a-form-item>
              </div>
            </div>
            <div class="input-row">
              <div class="input-row-left noto500 min-w-[200px]">
                {{ $t('common.store_telephone_number') }}
              </div>
              <div class="input-row-right">
                <a-form-item name="phone">
                  <a-input :type="number" v-model:value="shopInfo.phone" @keypress="handleKeyPress" />
                </a-form-item>
              </div>
            </div>
            <div class="input-row">
              <div class="input-row-left noto500 min-w-[200px]">
                {{ $t('common.address') }}
              </div>
              <div class="input-row-right">
                <a-form-item name="address">
                  <a-input v-model:value="shopInfo.address" />
                </a-form-item>
              </div>
            </div>
            <div class="input-row">
              <div class="input-row-left noto500 min-w-[200px]">
                {{ $t('common.area') }}
              </div>
              <div class="input-row-right">
                <a-form-item name="location">
                  <a-select :placeholder="areaNameOfShop" :options="areaList" @change="handleSelectedArea" showSearch>
                    <template #suffixIcon>
                      <span v-html="chevronDownSelectIcon"></span> </template></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="input-row">
              <div class="input-row-left noto500 min-w-[200px]">
                {{ $t('common.account_creation_date') }}
              </div>
              <div
                class="input-row-right overflow-hidden flex-1 flex justify-left items-center h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none">
                <a-date-picker class="w-full h-full left-date-picker" :format="dateFormat" picker="date"
                  :allowClear="false" :placeholder="''" v-model:value="dateSelected" :locale="locale">
                  <template #suffixIcon>
                    <span v-html="DateIcon"></span>
                  </template>
                </a-date-picker>
              </div>
            </div>
            <div class="input-row">
              <div class="input-row-left noto500 min-w-[200px]">
                {{ $t('common.email_address') }}
              </div>
              <div class="input-row-right">
                <a-form-item name="email">
                  <a-input v-model:value="shopInfo.email" />
                </a-form-item>
              </div>
            </div>
            <div class="input-row">
              <div class="input-row-left noto500 min-w-[200px]">
                {{ $t('common.store_image') }}
              </div>
              <div class="input-row-right photo !w-[80%]">
                <a-form-item name="">
                  <label for="photo-up"
                    class="custom-photo-up border cursor-pointer border-orage-text px-4 py-3 rounded-lg text-orage-text flex w-fit items-center justify-center gap-[8px] whitespace-nowrap">
                    <span v-html="UploadDocumentIcon"></span>
                    <span>{{ $t('common.upload') }}</span>
                  </label>
                  <input type="file" id="photo-up" multiple="multiple" accept="image/*"
                    @change="handleEventChangeFileImages" />
                </a-form-item>
                <div class="store-photo-list mt-2 flex flex-wrap gap-3">
                  <div class="store-photo-item relative" v-for="(item, index) in shopImageOldRenders" :key="index">
                    <img class="store-photo w-[130px] h-[80px] object-cover rounded-lg" :src="item.src"
                      alt="store-photo" />
                    <div
                      class="delete-photo-icon absolute bottom-1 right-1 bg-delete-text border-[2px] border-white flex items-center justify-center rounded-lg w-[30px] h-[30px] cursor-pointer"
                      v-html="trashFilledIconNew" @click="
                        handleDeleteShopImage(item.type, item.name, index)
                        "></div>
                  </div>
                  <div class="store-photo-item relative" v-for="(item, index) in shopImageNewRenders" :key="index">
                    <img class="store-photo w-[130px] h-[80px] object-cover rounded-lg" :src="item.src"
                      alt="store-photo" />
                    <div
                      class="delete-photo-icon absolute bottom-1 right-1 bg-delete-text border-[2px] border-white flex items-center justify-center rounded-lg w-[30px] h-[30px] cursor-pointer"
                      v-html="trashFilledIconNew" @click="
                        handleDeleteShopImage(item.type, item.name, index)
                        "></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-submit-row flex justify-end items-center !pb-[0px]">
              <div class="input-row-left"></div>
              <div class="input-row-right flex justify-end items-center">
                <a-form-item>
                  <button class="button-submit" type="submit">
                    <label v-html="SaveSettingFilled" class="mr-[6px] cursor-pointer"></label>
                    {{ $t('common.keep') }}
                  </button>
                </a-form-item>
              </div>
            </div>
          </a-form>
        </div>
        <div
          class="flex-1 h-full min-h-[862px] p-[24px] pr-[0px] border-solid border-[1px] border-[#D0D5DD] bg-white rounded-[12px]">
          <h3 class="h-[42px] flex justify-start items-center text-700 text-[20px] leading-[32px] text-[#475467]">
            {{ t('common.currently_streaming_users') }}
          </h3>
          <div class="max-h-[771px] overflow-y-auto scroll-bar scroll-bar-small pr-[24px]">
            <UserStreaming v-for="u in userLive" key="u?._id" :data="u?.user" />
          </div>
        </div>
      </div>
      <div v-if="currentTab == 2 && !userTab" class="min-w-[1000px] w-full">
        <UserByStore />
      </div>
      <div v-if="currentTab == 2 && userTab == 'active'" class="min-w-[1000px] w-full">
        <UserDetailActive />
      </div>
      <div v-if="currentTab == 2 && userTab == 'disable'"
        class="min-w-[1000px] w-full flex items-center justify-center">
        <UserDetailDisable />
      </div>
      <div v-if="currentTab == 2 && userTab == 'pending'"
        class="min-w-[1000px] w-full flex items-center justify-center">
        <UserDetailDisable />
      </div>
      <div v-if="currentTab == 2 && userTab == 'deleted'"
        class="min-w-[1000px] w-full flex items-center justify-center">
        <UserDetailDisable />
      </div>
      <div v-if="currentTab == 2 && userTab == 'created'"
        class="min-w-[1000px] w-full flex items-center justify-center">
        <UserDetailDisable />
      </div>
      <div v-if="currentTab == 2 && userTab == 'expired'"
        class="min-w-[1000px] w-full flex items-center justify-center">
        <UserDetailDisable />
      </div>
      <div v-if="currentTab == 3" class="min-w-[1000px] w-full flex items-center justify-center">
        <AccountPermission />
      </div>
      <div v-if="currentTab == 4" class="min-w-[1000px] w-full flex items-center justify-center">
        <RenewUserAccountPage />
      </div>
    </div>
  </div>
  <!-- MOBILE ONLY -->
  <div v-if="layoutMobile"
    class="store-info-container-mobile w-screen min-h-screen pb-4 px-[24px] py-[21px] bg-[#f9fafb]">
    <span class="text-header-bold text-[24px] noto700">
      {{ userTab ? $t('common.personal_info') : nameByIndex }}
    </span>
    <div class="store-breadcrumb pt-[14px] pb-[24px]">
      <Breadcrumd :routes="routes" />
    </div>
    <div v-if="layoutMobile" :class="{
      'navigation-tab flex  w-full items-center self-start  my-3 py-2  whitespace-nowrap overflow-auto': true
    }">
      <div v-for="(item, index) in navigateTab" :key="index">
        <div :class="{
          'px-5 pb-2 cursor-pointer noto400 ': true,
          'border-b-2 text-[#F38744] border-[#F38744] !font-bold':
            currentTab == index + 1
        }" @click="handleNavigateTabClick(index + 1)">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="shop-action w-full flex flex-col gap-[20px] mb-[24px]" v-if="currentTab == 1 && layoutMobile">
      <button type="button"
        class="w-full h-[42px] flex justify-center items-center bg-orage-text text-white px-2 py-1 md:py-[13px] md:px-4 rounded-lg whitespace-nowrap"
        @click="handleDisableStoreAccount">
        {{ $t('common.store_hidden') }}
      </button>
    </div>
    <div v-if="currentTab == 1 && layoutMobile"
      class="create-store-form bg-white p-[24px] min-w-full rounded-lg border border-gray-border">
      <div class="form-header !flex-row flex justify-between items-center relative w-full">
        <div class="shop-name text-gray600 noto700 text-[20px] uppercase">
          {{ shopInfo.name }}
        </div>
        <button :disabled="loadingSendEmail" v-if="shopInfo?.adminShop?.firstLogin === 1" type="button" :class="{
          'w-[144px] h-[42px] flex justify-center items-center notosans500 text-[14px] leading-[18px] text-white bg-[#12B76A] rounded-[8px] border-solid border-[1px] border-[#12B76A]': true,
          'bg-[#3b976c]': loadingSendEmail
        }" @click="handleResendEmail">
          {{ $t('common.resend_confirmation_email') }}
        </button>
      </div>

      <a-form :model="shopInfo" @finish="onSaveUpdate" autocomplete="off" class="w-full">
        <div class="input-row w-full flex justify-between items-center !flex-row">
          <div class="input-row-left noto500 w-[114px]">
            {{ $t('common.store_logo_new') }}
          </div>
          <div class="input-row-right !flex-1 !w-[124px]">
            <a-form-item name="" class="!w-full">
              <div class="logo-section !w-full flex flex-row justify-end items-center gap-[12px]">
                <div class="avatar-container w-[80px] h-[80px]">
                  <div class="store-logo relative w-[80px] h-[80px]">
                    <img v-on:click="handleUploadAvatar" id="avatarShop"
                      class="w-[80px] h-[80px] object-cover cursor-pointer rounded-full" :src="shopAvatarRender" alt=""
                      @error="(e) => handleImageError(e, 'shop')" @load="(e) => handleAvatarLoadingFinish(e)" />
                    <button type="button" :disabled="loadingAvatarShop" v-on:click="handleUploadAvatar" :class="{
                      'flex justify-center items-center absolute bottom-0 right-0 w-[32px] h-[32px] rounded-full bg-[#F38744]': true,
                      '!bg-white': loadingAvatarShop
                    }">
                      <a-spin v-if="loadingAvatarShop" class="flex justify-center items-center w-[20px] h-[20px]" />
                      <span v-else class="icon-camera" v-html="IconCamera"></span>
                    </button>
                  </div>
                  <input type="file" id="shop-avatar" accept="image/*" @change="handleEventChangeFileAvatar" />
                </div>
                <button type="button" :disabled="loadingAvatarShop"
                  class="delete-btn flex justify-center items-center gap-[6px] px-4 py-2 border-[1px] border-delete-text rounded-lg ml-4 xs:ml-0 cursor-pointer"
                  v-if="shopAvatarRender !== null" @click="handleDeleteShopAvatar">
                  <div v-html="TrashFilledIcon"></div>
                </button>
              </div>
            </a-form-item>
          </div>
        </div>
        <div class="input-row w-full flex flex-col justify-start items-start gap-[12px]">
          <div class="input-row-left noto500 !w-full text-left">
            {{ $t('common.store_name') }}
          </div>
          <div class="input-row-right w-full">
            <a-form-item name="name">
              <a-input v-model:value="shopInfo.name" class="w-full" />
            </a-form-item>
          </div>
        </div>
        <div class="input-row w-full flex flex-col justify-start items-start gap-[12px]">
          <div class="input-row-left noto500 !w-full text-left">
            {{ $t('common.store_telephone_number') }}
          </div>
          <div class="input-row-right w-full">
            <a-form-item name="phone">
              <a-input :type="number" v-model:value="shopInfo.phone" @keypress="handleKeyPress" />
            </a-form-item>
          </div>
        </div>
        <div class="input-row w-full flex flex-col justify-start items-start gap-[12px]">
          <div class="input-row-left noto500 !w-full text-left">
            {{ $t('common.address') }}
          </div>
          <div class="input-row-right w-full">
            <a-form-item name="address">
              <a-input v-model:value="shopInfo.address" />
            </a-form-item>
          </div>
        </div>
        <div class="input-row w-full flex flex-col justify-start items-start gap-[12px]">
          <div class="input-row-left text-left noto500 !w-full">
            {{ $t('common.area') }}
          </div>
          <div class="input-row-right w-full">
            <a-form-item name="location">
              <a-select :placeholder="areaNameOfShop" :options="areaList" @change="handleSelectedArea" showSearch
                class="flex-1 w-full rounded-[8px] px-[14px] py-[10px] bg-white outline-none">
                <template #suffixIcon>
                  <span v-html="chevronDownSelectIcon"></span> </template></a-select>
            </a-form-item>
          </div>
        </div>
        <div class="input-row w-full flex flex-col justify-start items-start gap-[12px]">
          <div class="input-row-left noto500 !w-full text-left">
            {{ $t('common.account_creation_date') }}
          </div>
          <div
            class="input-row-right overflow-hidden flex-1 flex justify-left items-center w-full !h-[40px] max-h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none">
            <a-date-picker class="w-full h-[40px]" :format="dateFormat" picker="date" :allowClear="false"
              :placeholder="''" v-model:value="dateSelected" :locale="locale">
              <template #suffixIcon>
                <span v-html="DateIcon"></span>
              </template>
            </a-date-picker>
          </div>
        </div>
        <div class="input-row w-full flex flex-col justify-start items-start gap-[12px]">
          <div class="input-row-left noto500 !w-full text-left">
            {{ $t('common.email_address') }}
          </div>
          <div class="input-row-right w-full">
            <a-form-item name="email">
              <a-input v-model:value="shopInfo.email" />
            </a-form-item>
          </div>
        </div>
        <div class="input-row w-full flex flex-col justify-start items-start gap-[12px]">
          <div class="input-row-left noto500 !w-full text-left">
            {{ $t('common.store_image') }}
          </div>
          <div class="input-row-right photo w-full">
            <a-form-item name="">
              <label for="photo-up"
                class="custom-photo-up w-full h-[40px] border cursor-pointer border-orage-text px-4 py-3 rounded-lg text-orage-text flex items-center justify-center gap-[8px] whitespace-nowrap">
                <span v-html="UploadDocumentIcon"></span>
                <span>{{ $t('common.upload') }}</span>
              </label>
              <input type="file" id="photo-up" multiple="multiple" accept="image/*"
                @change="handleEventChangeFileImages" />
            </a-form-item>
            <div class="store-photo-list flex flex-wrap justify-center items-start gap-[12px] mt-[18px]">
              <div class="store-photo-item relative" v-for="(item, index) in shopImageOldRenders" :key="index">
                <img class="store-photo w-[130px] h-[80px] object-cover rounded-lg" :src="item.src" alt="store-photo" />
                <div
                  class="delete-photo-icon absolute bottom-1 right-1 bg-delete-text border-[2px] border-white flex items-center justify-center rounded-lg w-[30px] h-[30px] cursor-pointer"
                  v-html="trashFilledIconNew" @click="handleDeleteShopImage(item.type, item.name, index)"></div>
              </div>
              <div class="store-photo-item relative" v-for="(item, index) in shopImageNewRenders" :key="index">
                <img class="store-photo w-[130px] h-[80px] object-cover rounded-lg" :src="item.src" alt="store-photo" />
                <div
                  class="delete-photo-icon absolute bottom-1 right-1 bg-delete-text border-[2px] border-white flex items-center justify-center rounded-lg w-[30px] h-[30px] cursor-pointer"
                  v-html="trashFilledIconNew" @click="handleDeleteShopImage(item.type, item.name, index)"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="input-submit-row flex !flex-row justify-center items-center gap-[12px] !pb-[0px]">
          <button type="button"
            class="h-[40px] flex-1 flex justify-center items-center text-delete-text border border-delete-text px-2 py-1 md:py-3 md:px-4 rounded-lg whitespace-nowrap"
            @click="handleDeletePermanentlyStoreAccount">
            {{ $t('common.store_deletion') }}
          </button>
          <div class="input-row-right flex flex-1 justify-center items-center">
            <a-form-item class="w-full min-w-full">
              <button class="button-submit !w-full h-[40px] flex justify-center items-center" type="submit">
                <label v-html="SaveSettingFilled" class="mr-[6px] cursor-pointer"></label>
                {{ $t('common.keep') }}
              </button>
            </a-form-item>
          </div>
        </div>
      </a-form>
    </div>
    <div v-show="currentTab == 1 && layoutMobile"
      class="flex-1 h-full min-h-[300px] p-[24px] pr-[0px] border-solid border-[1px] border-[#D0D5DD] bg-white rounded-[12px] mt-[24px]">
      <h3 class="h-[42px] flex justify-start items-center text-700 text-[20px] leading-[32px] text-[#475467]">
        {{ t('common.currently_streaming_users') }}
      </h3>
      <div class="max-h-[771px] overflow-y-auto scroll-bar scroll-bar-small pr-[24px]">
        <UserStreaming v-for="u in userLive" key="u?._id" :data="u?.user" />
      </div>
    </div>
    <div v-if="currentTab == 2 && !userTab && layoutMobile" class="w-full flex items-center justify-center">
      <UserByStore />
    </div>
    <div v-if="currentTab == 2 && userTab == 'active' && layoutMobile" class="w-full flex items-center justify-center">
      <UserDetailActive />
    </div>
    <div v-if="currentTab == 2 && userTab == 'disable' && layoutMobile" class="w-full flex items-center justify-center">
      <UserDetailDisable />
    </div>
    <div v-if="currentTab == 2 && userTab == 'pending' && layoutMobile" class="w-full flex items-center justify-center">
      <UserDetailDisable />
    </div>
    <div v-if="currentTab == 2 && userTab == 'delete' && layoutMobile" class="w-full flex items-center justify-center">
      <UserDetailDisable />
    </div>
    <div v-if="currentTab == 2 && userTab == 'created' && layoutMobile" class="w-full flex items-center justify-center">
      <UserDetailDisable />
    </div>
    <div v-if="currentTab == 2 && userTab == 'expired' && layoutMobile" class="w-full flex items-center justify-center">
      <UserDetailDisable />
    </div>
    <div v-if="currentTab == 3 && layoutMobile" class="w-full flex items-center justify-center">
      <AccountPermission />
    </div>
    <div v-if="currentTab == 4 && layoutMobile" class="w-full flex items-center justify-center">
      <RenewUserAccountPage />
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  SaveSettingFilled,
  TrashFilledIcon,
  UploadDocumentIcon,
  chevronDownSelectIcon,
  trashFilledIconNew
} from '../../../assets/constant/constant';
import { IconCamera } from '../../../assets/constant/svg';
import {
  addModal,
  createFormDataFromObject,
  formatServerDateToDDMMYYYY,
  getAvatarShopByName,
  getCurrentDate,
  handleImageError,
  showAlert
} from '../../../helpers';
import Breadcrumd from '../../master/Breadcrumd.vue';
import ConfirmModal from './components/ConfirmModal.vue';
import locale from 'ant-design-vue/es/date-picker/locale/ja_JP';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import UserStreaming from './components/UserStreaming.vue';
import UserByStore from '../UserManagermentNew/UserByStore.vue';
import AccountPermission from '../UserManagermentNew/AccountPermission.vue';
import RenewUserAccountPage from '../RenewNew/RenewUserAccountPage.vue';
import UserDetailActive from '../UserManagermentNew/UserDetailActive.vue';
import UserDetailDisable from '../UserManagermentNew/UserDetailDisable.vue';
import { getToken } from '../../../helpers/token';
const dateFormat = 'YYYY年M月D日';
dayjs.locale('ja');

const store = useStore();
const token = getToken();
const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const layoutMobile = computed(() => store.state.layoutMobile);
const userLive = computed(() => store.state.userLive);
const userInShop = computed(() => store.state.userInShop);
const loadingSendEmail = ref(false);
// Data
const defaultShopInfo = {
  name: '',
  phone: '',
  email: '',
  address: '',
  location: ''
};
const shopInfo = computed(() => {
  return { ...defaultShopInfo, ...store.state.shopDetails };
});

// Tab
const currentTab = computed(() => route.query.shop_tab || 1);
const userTab = computed(() => route.query.user_tab);
const navigateTab = computed(() => [
  {
    name: shopInfo.value.name || defaultShopInfo.name
  },
  {
    name: t('common.total_users')
  },
  {
    name: t('common.account_permission')
  },
  {
    name: t('common.extended_management')
  }
]);
const nameByIndex = computed(() => {
  return (
    navigateTab.value[currentTab.value - 1]?.name?.replace(/['"]/g, '') ?? ''
  );
});
const routes = computed(() => {
  if (userTab.value == 'active') {
    return [
      {
        path: '/dashboard',
        breadcrumbName: t('common.home')
      },
      {
        path: '/store-management',
        breadcrumbName: t('common.stores_management')
      },
      {
        path: '',
        breadcrumbName: nameByIndex.value
      },
      {
        path: '',
        breadcrumbName: t('common.personal_info')
      }
    ];
  }
  return [
    {
      path: '/dashboard',
      breadcrumbName: t('common.home')
    },
    {
      path: '/store-management',
      breadcrumbName: t('common.stores_management')
    },
    {
      path: '',
      breadcrumbName: nameByIndex
    }
  ];
});
// Image variables
const shopImageNewRenders = ref([]);
const shopImageOldRenders = ref([]);
const shopAvatarRender = ref(null);
const shopImageFileUpload = ref([]);
const shopImageFileDelete = ref([]);
const shopAvatarFileUpload = ref(null);
const shopAvatarFileDelete = ref(false);
const loadingAvatarShop = ref(true);
// calculate the image
const multiImage = computed(() => {
  return [shopAvatarFileUpload.value]
    .concat(shopImageFileUpload.value)
    ?.filter((file) => {
      return file !== null && file !== undefined && file !== '' && file;
    });
});
const deleteMultiImage = computed(() => {
  return shopImageFileDelete.value.length === 1
    ? ['trigger.jpg'].concat(shopImageFileDelete.value)
    : shopImageFileDelete.value;
});

// Handle avatar image upload (event onchange from input to activate function)
const handleEventChangeFileAvatar = (event) => {
  const fileUpload = event.target.files[0];
  shopAvatarFileUpload.value = fileUpload;
  const fileRender = URL.createObjectURL(fileUpload);
  shopAvatarRender.value = fileRender;
};

// Handle shop image upload (event onchange from input to activate function)
const handleEventChangeFileImages = (event) => {
  const files = event.target.files;
  if (files) {
    Array.from(files).forEach((file) => {
      shopImageFileUpload.value.push(file);
      shopImageNewRenders.value.push({
        src: URL.createObjectURL(file),
        type: 'new',
        name: URL.createObjectURL(file)
      });
    });
  }
};

const handleDeleteShopImage = (type, name, index) => {
  if (type === 'old') {
    shopImageOldRenders.value.splice(index, 1);
    shopImageFileDelete.value.push(name);
  } else if (type === 'new') {
    shopImageNewRenders.value.splice(index, 1);
    shopImageFileUpload.value.splice(index, 1);
  }
};

// After shop infomation render or change then create value avatar and set shop image render
watch(shopInfo, () => {
  shopAvatarRender.value =
    getAvatarShopByName(shopInfo.value?.avatar) ?? getAvatarShopByName('');
  navigateTab.value[0].name = shopInfo.value.name;
  shopImageOldRenders.value = shopInfo.value?.images
    ?.map((image) => {
      if (image) {
        return {
          src: getAvatarShopByName(image),
          type: 'old',
          name: image
        };
      } else {
        return null;
      }
    })
    ?.filter((image) => image !== null);
});

// Handle Image action & UI
const handleAvatarLoadingFinish = () => {
  loadingAvatarShop.value = false;
};
const handleUploadAvatar = () => {
  if (!loadingAvatarShop.value && document.getElementById('shop-avatar')) {
    document.getElementById('shop-avatar').click();
  }
};

// Date
const dateSelected = ref(dayjs(getCurrentDate(), 'YYYY-MM-DD'));

// Time Create
watch(shopInfo, () => {
  if (shopInfo.value?.createdAt && shopInfo.value?.createdAt !== '') {
    const dateOld = formatServerDateToDDMMYYYY(shopInfo.value?.createdAt);
    dateSelected.value = dayjs(dateOld, 'YYYY-MM-DD');
  }
});

// Location
const areaList = computed(() => store.getters.areaListGetters());
const areaNameOfShop = computed(() => {
  return areaList.value?.find((area) => {
    return area.id === shopInfo.value.location;
  })?.value;
});
const handleSelectedArea = (value) => {
  shopInfo.value.location = areaList.value.find(
    (area) => area.value === value
  )?.id;
};

// Handle phone character
const handleKeyPress = (event) => {
  const charCode = event.charCode || event.keyCode;
  if (charCode < 48 || charCode > 57) {
    event.preventDefault();
  }
};

// Handle delete avatar
const handleDeleteShopAvatar = () => {
  if (!loadingAvatarShop.value && shopAvatarFileUpload !== null) {
    addModal(ConfirmModal, {
      title: t('common.delete_photo'),
      textBtnClose: t('common.cancel_delete'),
      textBtnConfirm: t('common.delete_photo'),
      onConfirm: () => {
        shopAvatarFileUpload.value = null;
        shopAvatarRender.value = getAvatarShopByName('');
        shopAvatarFileDelete.value = true;
      }
    });
  } else {
    showAlert(
      'warning', t('common.profile_picture_empty'), 'top-center'
    );
  }
};

const handleResendEmail = () => {
  const email = shopInfo.value.email;
  if (email) {
    loadingSendEmail.value = true;
    store.dispatch("resendEmail", {
      token: token ?? '',
      email: shopInfo.value?.email,
      onOffLoadingSendEmail: () => { loadingSendEmail.value = false }
    })
  } else {
    showAlert(
      'warning', t('common.the_store_has_not_provided_an_email_address'), 'top-center'
    );
  }
};

// Handle delete permanently shop account
const handleDeletePermanentlyStoreAccount = () => {
  addModal(ConfirmModal, {
    title: t('common.are_you_sure_you_want_to_delete_this_store_permanently'),
    textBtnClose: t('common.cancel_delete'),
    textBtnConfirm: t('common.confirm'),
    onConfirm: () => {
      store.dispatch('deletePermanentlyShop', {
        shopId: route.query.id,
        navigation: () => {
          router.replace('/store-management');
        }
      });
    },
    action: 'delete'
  });
};
const handleDisableStoreAccount = () => {
  addModal(ConfirmModal, {
    title: t('common.do_you_want_to_hide_this_store'),
    textBtnClose: t('common.cancel_delete'),
    textBtnConfirm: t('common.confirm'),
    onConfirm: () => {
      store.dispatch('deleteShop', {
        shopId: route.query.id,
        navigation: () => {
          router.replace(`/store-management-disable?id=${shopInfo.value?._id}`);
        }
      });
    }
  });
};
const onSaveUpdate = (values) => {
  const data = {
    ...values,
    checkNotAvatar: 1,
    deleteAvatar: 1,
    multiImage: multiImage.value,
    deleteMultiImage: deleteMultiImage.value,
    createdAt: dateSelected.value
  };
  if (shopAvatarFileUpload.value !== null && shopAvatarRender.value !== null) {
    delete data.checkNotAvatar;
  }
  if (
    !shopAvatarFileDelete.value ||
    (shopAvatarFileUpload.value !== null && shopAvatarRender.value !== null)
  ) {
    delete data.deleteAvatar;
  }
  if (multiImage.value.length <= 0) {
    delete data.multiImage;
  }
  if (shopImageFileDelete.value.length <= 0) {
    delete data.deleteMultiImage;
  }
  const formData = createFormDataFromObject(data);
  store.dispatch('updateShopInfo', {
    shopId: route.query.id,
    shopInfo: formData,
    reload: () => {
      shopAvatarRender.value = null;
      shopAvatarFileUpload.value = null;
      shopAvatarFileDelete.value = false;
      shopImageFileDelete.value = [];
      shopImageFileUpload.value = [];
      shopImageNewRenders.value = [];
      shopImageOldRenders.value = [];
      store.dispatch('getAllShopAllStatus');
      store.dispatch('getShopDetails', {
        shopId: route.query.id,
        navigationError: () => {
          router.replace('/store-management');
        }
      });
    }
  });
};

// handle navigate tab click
const handleNavigateTabClick = (index) => {
  router.push(
    `/store-management-${shopInfo.value.status}?id=${shopInfo.value._id}&shop_tab=${index}`
  );
};
watch(shopInfo, () => {
  if (
    shopInfo.value?.status !== 'active' &&
    shopInfo.value?.status === 'disable'
  ) {
    router.replace(
      `/store-management-${shopInfo.value.status}?id=${shopInfo.value._id}`
    );
  }
});
watch(userInShop, () => {
  if (userInShop.value?.length > 0) {
    store.dispatch('getUserLive', {
      token: token ?? ''
    });
  }
});
onUnmounted(() => {
  store.dispatch('clearUserLive');
});
onMounted(() => {
  store.dispatch('getAreaList');
  store.dispatch('getUserInShop', {
    shopId: route.query.id,
    token: token ?? ''
  });
  store.dispatch('getShopDetails', {
    shopId: route.query.id,
    navigationError: () => {
      router.replace('/store-management');
    }
  });
});
onUnmounted(() => {
  store.dispatch('clearShopDetail');
});
</script>
<style lang="scss">
$white-primary: white;
$gray-300: #d0d5dd;
$gray-500: #667085;
$gray-700: #344054;
$orange-500: #f38744;

.store-info-container,
.store-info-container-mobile {
  img {
    border: 1px solid $gray-300;
  }

  input[type='file'] {
    display: none !important;
  }

  input {
    height: 40px !important;
  }

  .form-header {
    @media (max-width: 670px) {
      flex-direction: column;
      gap: 10px;

      .form-header-left {
        align-self: flex-start;
      }
    }
  }

  .input-submit-row,
  .input-row {
    padding: 20px 0px 20px 0px;
    display: flex;
    justify-content: start;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid $gray-300;

    .input-row-left {
      width: 15%;
      color: $gray-500;
      font-size: 14px;

      @media (max-width: 800px) {
        width: 100%;
      }
    }

    .input-row-right {
      flex: 1;

      @media (max-width: 800px) {
        width: 100%;
      }

      .button-submit {
        background-color: $orange-500;
        color: $white-primary;
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 12px 16px;
        border-radius: 8px;
      }
    }
  }

  .input-submit-row {
    border-bottom: 0;

    .input-row-right {
      width: fit-content;
    }

    @media (max-width: 800px) {
      align-items: center !important;
      justify-content: center !important;
    }
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .ant-input:focus,
  .ant-input:hover {
    border-color: $gray-300;
    outline: none;
    box-shadow: none;
  }

  input {
    font-family: Noto400;
    font-size: 14px;
    line-height: 22px;
    color: #1d2939;
  }

  .ant-picker {
    width: 100% !important;
    padding-left: 0px !important;
  }
}

.left-date-picker {
  .ant-picker-input {
    input {
      text-align: left !important;
    }
  }
}
</style>
