import axiosConfig from '../../config/axiosConfig';

import CryptoJS from 'crypto-js';
import VueCookies from 'vue-cookies';
import { ENCODE_SECRET_KEY } from '../../constants';

export const authActions = {
  async login(context, payload) {
    try {
      const res = await axiosConfig.post('/auth/login', payload.data);
      if (res?.status === 200) {
        VueCookies.set(
          'access_token_master',
          CryptoJS.AES.encrypt(
            res.data.access_token,
            ENCODE_SECRET_KEY
          ).toString(),
          payload?.data?.rememberMe ? '30d' : '1d'
        );
        payload.onTurnOffLoading();
        context.commit('LOGIN', {
          navigator: payload.onNaviLoginSuccess,
          userLogin: res.data
        });
      } else {
        payload.onTurnOffLoading();
        context.commit('UNAUTHORIZED_USER_MODAL');
      }
    } catch (errors) {}
  }
};
