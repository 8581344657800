export const shopsState = {
  allShopsAllStatus: [],
  allShops: [],
  shopRequestList: [],
  shopDetails: {},
  shopRequestInfo: {},
  areaList: [],
  dashboardGiftPointData: [],
  dashboardLiveStreamData: [],
  totalTimesOfLive: []
};
