<template la>
  <div class="dashboard-container px-[24px] py-[21px] bg-[#F9FAFB] scroll-bar">
    <span class="text-header-bold text-[24px] noto700">
      {{ $t('common.dashboard') }}
    </span>
    <div class="dashboard-breadcrumb pt-[14px] pb-[24px]">
      <Breadcrumd :routes="routes" />
    </div>
    <!-- <div class="shop-info border border-gray-border rounded-lg bg-white">
      <div
        class="shop-info-header border-b border-b-gray-border text-center py-5 text-gray600 noto700"
      >
        {{ $t("common.store_info") }}
      </div>
      <div class="shop-info-status grid grid-cols-1 md:grid-cols-3">
        <ShopInfoStatusItem
          v-for="(item, index) in ShopStatusItemData"
          :key="index"
          :data="item"
        />
      </div>
    </div>
    <div class="total-user border border-gray-border rounded-lg mt-6 bg-white">
      <div
        class="total-user-header border-b border-b-gray-border text-center py-5 text-gray600 noto700"
      >
        {{ $t("common.total_number_of_users") }}({{ totalUser }})
      </div>
      <div
        class="total-user-table flex-wrap flex gap-6 p-5 w-full max-h-[470px] overflow-auto scroll-bar"
      >
        <UserItem
          v-if="userInShopItemData.length > 0"
          v-for="(item, index) in userInShopItemData"
          :key="index"
          :data="item"
        />
        <div v-else class="w-full min-h-[400px] flex justify-center items-center">
          <EmptyData />
        </div>
      </div>
    </div> -->
    <div
      :class="{
        'flex items-center justify-between gap-8': true,
        'flex-col': layoutMobile
      }"
    >
      <div class="control-left w-full bg-white">
        <div
          class="title bg-[#475467] text-white noto700 w-full text-center py-5 rounded-t-lg"
        >
          {{ $t('common.store_info') }}
        </div>
        <div
          class="body w-full border border-gray-border border-t-0 rounded-b-lg"
        >
          <div
            v-for="(item, index) in leftControlData"
            :key="index"
            class="flex items-center justify-between px-10 py-16 border-b border-gray-border last:border-b-0"
          >
            <div>
              <div class="noto700 text-[40px] text-[#182230]">
                {{ item.number }}
              </div>
              <div class="py-4">
                {{ item.subtitle }}
              </div>
            </div>
            <div>
              <span v-html="item.icon"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="control-right w-full bg-white">
        <div
          class="title bg-[#475467] text-white noto700 w-full text-center py-5 rounded-t-lg"
        >
          {{ $t('common.store_info') }}
        </div>
        <div
          class="body w-full border border-gray-border border-t-0 rounded-b-lg"
        >
          <div
            v-for="(item, index) in rightControlData"
            :key="index"
            class="flex items-center justify-between px-10 py-16 border-b border-gray-border relative last:border-b-0 last:max-h-[244px]"
          >
            <div>
              <div class="px-2 noto700 text-[40px] text-[#182230]">
                {{ item.number }}
              </div>
              <div class="flex items-center gap-2 py-4">
                <span v-html="item.subIcon"></span>{{ item.subtitle }}
              </div>
            </div>
            <div
              v-if="index == 1"
              class="absolute top-5 right-10 account-permission"
            >
              <a-date-picker
                :allowClear="false"
                :locale="locale"
                picker="month"
                v-model:value="totalLiveDurationSelectedDate"
                format="YYYY年M月"
              >
                <template #suffixIcon>
                  <span v-html="DateIcon"></span>
                </template>
              </a-date-picker>
            </div>
            <div
              v-if="index == 2"
              class="absolute top-5 right-10 account-permission"
            >
              <a-date-picker
                :allowClear="false"
                :locale="locale"
                picker="month"
                v-model:value="totalGiftSelectedDate"
                format="YYYY年M月"
              >
                <template #suffixIcon>
                  <span v-html="DateIcon"></span>
                </template>
              </a-date-picker>
            </div>
            <div>
              <span v-html="item.icon"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  hiddenHomeIcon,
  primaryHomeIcon
} from '../../../assets/constant/constant.js';
import Breadcrumd from '../../master/Breadcrumd.vue';
import locale from 'ant-design-vue/es/date-picker/locale/ja_JP';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import EmptyData from '../../master/EmptyData.vue';
import ShopInfoStatusItem from './components/ShopInfoStatusItem.vue';
import UserItem from './components/UserItem.vue';
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { getToken } from '../../../helpers/token.js';
import {
  houseOrangeIcon,
  houseOrangeActiveIcon,
  houseDisableIcon,
  peopleIcon,
  clockGreenIcon,
  giftYellowIcon,
  peopleGraySmallIcon,
  giftGraySmallIcon,
  clockGraySmallIcon,
  DateIcon
} from '../../../assets/constant/svg';
import { formatPoint, getCurrentDate } from '../../../helpers/index.js';
dayjs.locale('ja');
const { t } = useI18n();
const store = useStore();
const totalLiveDurationSelectedDate = ref(dayjs(getCurrentDate(), 'YYYY-MM'));
const totalGiftSelectedDate = ref(dayjs(getCurrentDate(), 'YYYY-MM'));

const routes = ref([
  {
    path: '/dashboard',
    breadcrumbName: t('common.home')
  },
  {
    path: '/dashboard',
    breadcrumbName: t('common.dashboard')
  }
]);
const lenShopActive = computed(() => {
  return store.getters.getLenShopActive();
});
const lenAllShop = computed(() => {
  return store.getters.getLenAllShop();
});
const totalGiftPoint = computed(() => {
  return store.getters.getTotalGiftPointGetters();
});
const totalLiveStreamTime = computed(() => {
  return store.getters.getTotalLiveStreamGetters();
});
const totalTimesOfLive = computed(() => {
  return store.getters.getTotalTimesOfLive();
});
const ShopStatusItemData = computed(() => {
  return [
    {
      number: lenAllShop.value,
      icon: primaryHomeIcon,
      subtitle: t('common.total_number_of_store')
    },
    {
      number: lenShopActive.value,
      icon: primaryHomeIcon,
      isActive: true,
      subtitle: t('common.store_under_contract')
    },
    {
      number: lenAllShop.value - lenShopActive.value,
      icon: hiddenHomeIcon,
      isHidden: true,
      subtitle: t('common.hidden_store')
    }
  ];
});
const leftControlData = computed(() => {
  return [
    {
      number: formatPoint(lenAllShop.value),
      subtitle: t('common.total_number_of_store'),
      icon: houseOrangeIcon
    },
    {
      number: formatPoint(lenShopActive.value),
      subtitle: t('common.store_under_contract'),
      icon: houseOrangeActiveIcon
    },
    {
      number: formatPoint(lenAllShop.value - lenShopActive.value),
      subtitle: t('common.hidden_store'),
      icon: houseDisableIcon
    }
  ];
});
const rightControlData = computed(() => {
  return [
    {
      number: formatPoint(totalTimesOfLive.value),
      subtitle: t('common.its_live'),
      icon: peopleIcon,
      subIcon: peopleGraySmallIcon
    },
    {
      number: totalLiveStreamTime.value,
      subtitle: t('common.total_live_time'),
      icon: clockGreenIcon,
      subIcon: clockGraySmallIcon
    },
    {
      number: formatPoint(totalGiftPoint.value) || 0,
      subtitle: t('common.total_gift_amount'),
      icon: giftYellowIcon,
      subIcon: giftGraySmallIcon
    }
  ];
});
const userInShopItemData = computed(() => {
  return store.getters.allUserListSorted();
});
const totalUser = computed(() => userInShopItemData.value.length);
const layoutMobile = computed(() => store.state.layoutMobile);
watch(totalLiveDurationSelectedDate, () => {
  // streaming
  store.dispatch('getTotalLiveStreamTime', {
    token: getToken(),
    month: dayjs(totalLiveDurationSelectedDate.value.$d).format('YYYY-MM')
  });
});
watch(totalGiftSelectedDate, () => {
  // gift point
  store.dispatch('getTotalGiftPoint', {
    token: getToken(),
    month: dayjs(totalGiftSelectedDate.value.$d).format('YYYY-MM')
  });
});
onMounted(() => {
  store.dispatch('getAllShopAllStatus', {
    token: getToken()
  });
  store.dispatch('getAllUsers', {
    token: getToken()
  });
  // streaming
  store.dispatch('getTotalLiveStreamTime', {
    token: getToken(),
    month: dayjs(totalGiftSelectedDate.value.$d).format('YYYY-MM')
  });
  // gift point
  store.dispatch('getTotalGiftPoint', {
    token: getToken(),
    month: dayjs(totalGiftSelectedDate.value.$d).format('YYYY-MM')
  });
});
</script>
<style lang="scss" scoped>
.account-permission {
  .ant-picker {
    width: 142px !important;
    height: 44px;
    padding: 12px 14px;
    border: 1px solid #d0d5dd;
    input {
      text-align: left !important;
      color: #1d2939;
    }
  }
}
</style>