import { DatePicker, Form, Input, Spin } from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import { createApp } from 'vue';
import img_shop_default from '../assets/images/other/default-store-logo.jpg';
import img_user_default from '../assets/images/other/no_avatar__1_.png';
import AlertFt from '../components/master/Alert.vue';
import i18n from '../i18n';
import store from '../store';

export const emptyString = ' ';

export const success = (msg) => {
  message.success(msg);
};

export const error = (msg) => {
  message.error(msg);
};

export const warning = (msg) => {
  message.warning(msg);
};

export const formatDollar = (value) => {
  let val = (value / 1).toFixed(3).replace('.', ',');
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};
export const formatPoint = (value) => {
  let val = (value / 1).toString();
  val = val.replace('.', ',');
  val = val.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return val;
};
export const formatDollarSmall = (value) => {
  let val = (value / 1).toFixed(10).replace('.', ',');
  return val.toString().replace(/\B(?=(\d{10})+(?!\d))/g, '.');
};

export const showAlert = (type, content, position) => {
  const alert = createApp(AlertFt, {
    alert: { type: type, content: content },
    position: position
  });
  const alertInstance = alert.mount(document.createElement('div'));
  document.body.appendChild(alertInstance.$el);

  const t = setTimeout(() => {
    if (alertInstance.$el && alertInstance.$el.parentNode === document.body) {
      document.body.removeChild(alertInstance.$el);
      alert.unmount();
    }
  }, 2000);

  return () => {
    clearTimeout(t);
    if (alertInstance.$el && alertInstance.$el.parentNode === document.body) {
      document.body.removeChild(alertInstance.$el);
      alert.unmount();
    }
  };
};

export const addModal = (modal, props) => {
  const modelContainer = document.createElement('div');
  modelContainer.classList.add('container-form-model-custom');
  const onClose = () => {
    document.body.removeChild(modelContainer);
    document.head.removeChild(styleElement);
  };
  const form = createApp(modal, {
    onClose: onClose,
    ...(props || {})
  });

  form.use(Input);
  form.use(Form);
  form.use(store);
  form.use(i18n);
  form.use(DatePicker);
  form.use(Spin);
  form.mount(modelContainer);
  document.body.appendChild(modelContainer);
  const styleElement = document.createElement('style');
  styleElement.innerHTML = `
        body {
          overflow-y: hidden;
        }
      `;
  document.head.appendChild(styleElement);
};
export const notifyModal = (modal, onSubmit) => {
  const modelContainer = document.createElement('div');
  modelContainer.classList.add('container-hide-account-modal');
  const onClose = () => {
    document.body.removeChild(modelContainer);
    document.head.removeChild(styleElement);
  };
  const form = createApp(modal, {
    onClose: onClose,
    onSubmit: onSubmit
  });
  form.use(i18n);
  form.mount(modelContainer);
  document.body.appendChild(modelContainer);
  const styleElement = document.createElement('style');
  styleElement.innerHTML = `
        body {
          overflow-y: hidden;
        }
      `;
  document.head.appendChild(styleElement);
};

export const choosePackageModal = (
  modal,
  packageActive,
  handleChangePackage
) => {
  const modelContainer = document.createElement('div');
  modelContainer.classList.add('container-renew-model-custom');
  const onClose = () => {
    document.body.removeChild(modelContainer);
    document.head.removeChild(styleElement);
  };
  const form = createApp(modal, {
    onClose: onClose,
    packageActive: packageActive,
    handleChangePackage: handleChangePackage
  });
  form.use(i18n);
  form.mount(modelContainer);
  document.body.appendChild(modelContainer);
  const styleElement = document.createElement('style');
  styleElement.innerHTML = `
        body {
          overflow-y: hidden;
        }
      `;
  document.head.appendChild(styleElement);
};
export const renewModal = (modal, onSubmit) => {
  const modelContainer = document.createElement('div');
  modelContainer.classList.add('container-renew-model-custom');
  const onClose = () => {
    document.body.removeChild(modelContainer);
    document.head.removeChild(styleElement);
  };
  const form = createApp(modal, {
    onClose: onClose,
    onSubmit: onSubmit
  });
  form.use(i18n);
  form.mount(modelContainer);
  document.body.appendChild(modelContainer);
  const styleElement = document.createElement('style');
  styleElement.innerHTML = `
        body {
          overflow-y: hidden;
        }
      `;
  document.head.appendChild(styleElement);
};

export const addSpinner = (modal) => {
  const modelContainer = document.createElement('div');
  modelContainer.classList.add('container-spinner-model-custom');
  const form = createApp(modal);
  form.use(store);
  form.use(i18n);
  form.use(DatePicker);
  form.use(Spin);
  form.mount(modelContainer);
  document.body.appendChild(modelContainer);
};

export const addFormBuyUser = (modal, price_of_pricing, package_info) => {
  const modelContainer = document.createElement('div');
  modelContainer.classList.add('container-form-model-custom');
  const onClose = () => {
    document.body.removeChild(modelContainer);
    document.head.removeChild(styleElement);
  };
  const form = createApp(modal, {
    onClose: onClose,
    price_of_pricing: price_of_pricing,
    package_info: package_info
  });
  form.use(store);
  form.use(Spin);
  form.use(i18n);
  form.use(DatePicker);
  form.mount(modelContainer);
  document.body.appendChild(modelContainer);
  const styleElement = document.createElement('style');
  styleElement.innerHTML = `
        body {
          overflow-y: hidden;
        }
      `;
  document.head.appendChild(styleElement);
};

export const addConfirmModal = (modal, modalInfo, onNextFunc) => {
  const modelContainer = document.createElement('div');
  modelContainer.classList.add('container-form-model-custom');
  const onClose = () => {
    document.body.removeChild(modelContainer);
    document.head.removeChild(styleElement);
  };
  const form = createApp(modal, {
    modalInfo: modalInfo,
    onClose: onClose,
    onNext: onNextFunc
  });
  form.use(store);
  form.use(i18n);
  form.use(DatePicker);
  form.mount(modelContainer);
  document.body.appendChild(modelContainer);
  const styleElement = document.createElement('style');
  styleElement.innerHTML = `
        body {
          overflow-y: hidden;
        }
      `;
  document.head.appendChild(styleElement);
};

export const passwordModal = (fn) => {
  const modelContainer = document.createElement('div');
  modelContainer.classList.add('container-password-model-custom');
  const onClose = () => {
    document.body.removeChild(modelContainer);
  };
  const form = createApp(fn, {
    onClose: onClose
  });
  form.use(i18n);
  form.use(Form);
  form.use(Input);
  form.mount(modelContainer);
  document.body.appendChild(modelContainer);
};

export const validateEmail = (value) => {
  const input = document.createElement('input');
  input.type = 'email';
  input.required = true;
  input.value = value;
  return /\S+@\S+\.\S+/.test(value);
};
export const validateUserId = (input) => {
  const regex = /^[a-z0-9_\-!@#$%^&*()+=\[\]{};':"\\|,.<>\/?]+$/;
  return regex.test(input) && input.length > 0;
};
export const clearCharactersNotSupported = (input) => {
  const regex = /[^a-z0-9_\-!@#$%^&*()+=\[\]{};':"\\|,.<>\/?]/g;
  const cleanedInput = input?.replace(regex, '');
  return cleanedInput;
};
export const containsNonWhitespaceCharacters = (input) => {
  const regex = /\S/;
  return regex.test(input);
};
export const shortenString = (string, maxLenght) => {
  return maxLenght < (string ?? '').length
    ? string.slice(0, maxLenght) + '...'
    : string;
};
export const removeAllWhitespace = (input) => {
  const regex = /\s+/g;
  return input.replace(regex, '');
};
export const validatePhoneNumber = (value) => {
  const regex = /^(\d{3})-(\d{4})-(\d+)$/;
  return regex.test(value) && value.length > 11;
};
export const formatYYYY = (value) => {
  const regex = /^(\d{4})$/;
  return regex.test(value) && value.length === 4;
};
export const formatYYYYMM = (value) => {
  const regex = /^(\d{4})-(\d{2})$/;
  return regex.test(value) && value.length === 7;
};
export const formatYYYYMMDD = (value) => {
  const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
  return regex.test(value) && value.length === 10;
};
export const formatTelephone = (value) => {
  let valueFormat = value.replace(/\D/g, '');
  if (valueFormat.length > 3 && valueFormat.length <= 7) {
    valueFormat = valueFormat.replace(/(\d{3})(\d+)/, '$1-$2');
  } else if (valueFormat.length > 7) {
    valueFormat = valueFormat.replace(/(\d{3})(\d{4})(\d+)/, '$1-$2-$3');
  }
  return valueFormat;
};
export const isPositiveInteger = (value) => {
  return /^\d+$/.test(value);
};
export const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};
export const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getCurrentDateYYYYMM = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  return `${year}-${month}`;
};

export const getCurrentDateYYYY = () => {
  const now = new Date();
  const year = now.getFullYear();
  return `${year}`;
};

export const formatDate = (date) => {
  const now = new Date(date);
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatDateFromDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatDateFromDateYYYYMM = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  return `${year}-${month}`;
};

export const getCurrentMonth = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  return `${year}-${month}`;
};
export const formatDateForJapanese = (date) => {
  const now = new Date(date);
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  return `${year}年${month}月${day}日`;
};

export const formatDateForJapaneseYYYYMM = (date) => {
  const now = new Date(date);
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  return `${year}年${month}月`;
};
export const formatServerDateToDDMMYYYYHHMM = (isoDate) => {
  const date = new Date(isoDate);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  const formattedDate = `${day}/${month}/${year} - ${hours}:${minutes}`;

  return formattedDate;
};
export const formatServerDateToDDMMYYYY = (isoDate) => {
  const date = new Date(isoDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
export const formatServerDateToMMYYYY = (isoDate) => {
  const date = new Date(isoDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const formattedDate = `${year}-${month}`;
  return formattedDate;
};

export const formatServerDateToJapaneseYYYYMMDD = (isoDate) => {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}年${month}月${day}日`;
};
export const formatDateForEn = (date) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();
  return `${month} ${day}, ${year}`;
};

export const formatDateForEnYYYYMM = (date) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();
  return `${month}, ${year}`;
};

export const compareBetweenToDate = (dateToCompare, dateFilterHook) => {
  const d1 = new Date(dateToCompare);
  const d2 = new Date(dateFilterHook);
  return d1.getTime() <= d2.getTime();
  // true if dateToCompare <= dateFilterHook false if dateToCompare > dateFilterHook
};

export const compareBetweenToDateYYYYMM = (dateToCompare, dateFilterHook) => {
  const d1 = new Date(dateToCompare + '-01');
  const d2 = new Date(dateFilterHook + '-01');
  return d1.getTime() <= d2.getTime();
  // true if dateToCompare <= dateFilterHook false if dateToCompare > dateFilterHook
};

export const useDebounce = (fn, delay) => {
  let timeoutId = null;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const createFormDataFromObject = (dataObject) => {
  const formData = new FormData();
  Object.keys(dataObject).forEach((key) => {
    const dataObjectIndex = dataObject[key];
    if (typeof dataObjectIndex === 'object' && Array.isArray(dataObjectIndex)) {
      dataObjectIndex.forEach((d) => {
        formData.append(key, d);
      });
    } else {
      formData.append(key, dataObjectIndex);
    }
  });
  return formData;
};

export const getAvatarUserByName = (name) => {
  return `${process.env.VUE_APP_URL_IMAGES}/avatars/${name}`;
};

export const getAvatarShopByName = (name) => {
  return `${process.env.VUE_APP_URL_IMAGES}/shops/${name}`;
};

export const getGiftIcon = (image) => {
  return `${process.env.VUE_APP_URL_IMAGES}/gifts/${image}`;
};

export const handleImageError = (event, type) => {
  if (type === 'shop') {
    event.target.src = img_shop_default;
  } else {
    event.target.src = img_user_default;
  }
};
export const getStatusUser = (user) => {
  const packageLive = user?.packageUser;
  const indexFirstItemPackageLive = packageLive ? 0 : -1;
  if (user?.status === 'disable') {
    return 'deleted';
  }
  if (user) {
    if (!packageLive || packageLive.length <= 0) {
      return 'created'; // init user
    }
    if (
      packageLive[indexFirstItemPackageLive]?.status === 'disable' ||
      packageLive[indexFirstItemPackageLive]?.package?.status === 'disable'
    ) {
      return 'disable'; // package status disable
    } else {
      if (packageLive[indexFirstItemPackageLive]?.package?.end_date) {
        const nowDate = getCurrentDate();
        const packageLiveEndDate = formatServerDateToDDMMYYYY(
          packageLive[indexFirstItemPackageLive]?.package?.end_date
        );
        if (compareBetweenToDate(nowDate, packageLiveEndDate)) {
          return 'active'; // has package and end date > now date
        } else {
          return 'expired'; // has package and end date < now date
        }
      } else {
        // first package haven't end day
        const nowDate = getCurrentDate();
        const findOldPackage = packageLive?.find((item) => {
          const endDate = item?.package?.end_date;
          return compareBetweenToDate(nowDate, endDate);
        });
        if (findOldPackage) {
          return 'active'; // has package and end date > now date
        } else {
          return 'pending'; // hasn't end date and has start date
        }
      }
    }
  }
};
