import moment from 'moment';
import { formatPoint } from '../../helpers';

export const shopGetters = {
  areaListGetters: (state) => () => {
    return (
      state.areaList?.map((area) => {
        return {
          label: area.name,
          value: area.name,
          id: area._id
        };
      }) ?? []
    );
  },
  getLenShopActive: (state) => () => {
    return (
      state.allShopsAllStatus?.filter((shop) => shop.status === 'active')
        .length ?? 0
    );
  },
  getLenAllShop: (state) => () => {
    return state.allShopsAllStatus?.length ?? 0;
  },
  getTotalGiftPointGetters: (state) => () => {
    let sum = 0;
    if (
      !state.dashboardGiftPointData ||
      state.dashboardGiftPointData.length == 0
    ) {
      return 0;
    }
    state?.dashboardGiftPointData?.forEach((element) => {
      sum += element.points;
    });
    return sum;
  },
  getTotalLiveStreamGetters: (state) => () => {
    let totalSeconds = 0;
    state.dashboardLiveStreamData.forEach((element) => {
      const startDate = moment(element.startDate);
      const endDate = element.endDate ? moment(element.endDate) : moment();

      if (startDate.isValid() && endDate.isValid()) {
        totalSeconds += endDate.diff(startDate, 'seconds');
      } else {
        console.warn('Invalid date detected:', element);
      }
    });
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${String(formatPoint(hours)).padStart(2, '0')}:${String(
      minutes
    ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  },
  getTotalTimesOfLive: (state) => () => {
    return state?.totalTimesOfLive?.length;
  }
};
